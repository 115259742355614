<template>
  <section
    v-if="variants.v1 === variant && sidebarComponents.length === 0"
    class="n-section-primary tw-min-h-[100svh]"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-self-center tw-pt-20 tw-text-center">
      <h2 v-if="vars.titleText" class="tw-mb-10 tw-text-[var(--c-primary)]">{{ vars.titleText }}</h2>
      <div class="tw-flex tw-flex-col tw-bg-white" style="border-radius: var(--rounded)">
        <div class="tw-flex tw-p-6 md:tw-p-10 xl:tw-px-16">
          <library-form
            v-if="form"
            :form="form"
            version="v2"
            :component="component"
            :is-form-name-visible="vars.isFormNameVisibleBool"
          ></library-form>
        </div>
      </div>
    </div>
  </section>
  <section
    v-if="variants.v2 === variant && sidebarComponents.length === 0"
    class="n-section-primary tw-min-h-[100svh]"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-self-center tw-pt-20 tw-text-center">
      <h1 v-if="vars.titleText" class="tw-mb-10 tw-text-[var(--c-primary)]">{{ vars.titleText }}</h1>
      <div class="tw-flex tw-flex-col tw-bg-white" style="border-radius: var(--rounded)">
        <div class="tw-flex tw-p-6 md:tw-p-10 xl:tw-px-16">
          <library-form
            v-if="form"
            :form="form"
            version="v2"
            :component="component"
            :is-form-name-visible="vars.isFormNameVisibleBool"
          ></library-form>
        </div>
      </div>
    </div>
  </section>
  <div
    v-else-if="variants.v1 === variant && sidebarComponents.length > 0"
    class="tw-p-6"
    :style="`background-color: var(--c-${vars.backgroundColor});border-radius: var(--rounded);color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <h4
      v-if="vars.titleText"
      class="tw-mb-4 tw-flex tw-flex-col tw-items-start tw-self-start tw-text-start tw-text-[var(--c-primary)]"
    >
      {{ vars.titleText }}
    </h4>

    <library-form v-if="form" :form="form" version="v1" :component="component"></library-form>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'ContactForm',

  mixins: [ComponentMixin],

  data() {
    return {
      form: null,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  created() {
    this.form = this.parseFormV2(this.vars.contactForm);
  },

  computed: {
    ...mapState(useWebsiteStore, ['sidebarComponents']),
  },
});
</script>
