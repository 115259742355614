<template>
  <section
    class="n-section-primary"
    v-if="variants.v1 === variant && sidebarComponents.length === 0"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div class="tw-flex tw-w-full tw-max-w-none tw-flex-col tw-items-center lg:tw-max-w-none lg:tw-items-center">
      <div
        class="tw-mb-12 tw-flex tw-w-full tw-flex-col tw-gap-4 lg:tw-flex-row lg:tw-items-center lg:tw-justify-between"
      >
        <h2 :style="`color: var(--c-${vars.titleColor});`">
          {{ vars.titleText }}
        </h2>

        <div class="tw-hidden tw-gap-3 lg:tw-flex">
          <nuxt-link
            v-if="buttons.length > 0"
            v-for="(button, index) in buttons"
            :key="`button-desktop-${index}`"
            :class="['tw-w-full lg:tw-w-auto', `n-${button.button_type}`, 'tw-text-center tw-uppercase']"
            :to="button.button_link"
          >
            {{ button.button_label }}
            <span class="btn-arrow">
              <svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                  fill="white"
                />
              </svg>
            </span>
          </nuxt-link>
        </div>
      </div>

      <div class="tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-gap-10">
        <div class="content-container">
          <div class="swiper-wrapper">
            <Swiper
              :key="'property-swiper-' + properties.length"
              :modules="[SwiperPagination]"
              :pagination="false"
              :slides-per-view="1"
              :space-between="20"
              effect="fade"
              :clickable="true"
              :watch-overflow="true"
              @slideChange="onSlideChange"
              :breakpoints="{
                320: { slidesPerView: 1, spaceBetween: 20 },
                1024: { slidesPerView: 2, spaceBetween: 24 },
                1280: { slidesPerView: 3, spaceBetween: 24 },
              }"
              @swiper="onSwiper"
              class="tw-w-full"
            >
              <SwiperSlide v-for="(property, index) in properties" :key="`latestproperties-property-${index}`">
                <theme2-properties-property-list-item
                  :property="property"
                  :is-match-information-hidden="true"
                  version="v1"
                  :index="index"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div class="tw-w-full">
          <LibrarySliderPagination
            :swiper="swiper"
            variant="v7"
            :can-paginate-back="canPaginateBack"
            :can-paginate-next="canPaginateNext"
          />
        </div>
      </div>

      <div class="tw-mt-10 tw-block lg:tw-hidden">
        <nuxt-link
          v-for="(button, index) in buttons"
          :key="`button-mobile-${index}`"
          :class="['tw-w-full sm:tw-w-auto', `n-${button.button_type}`, 'tw-text-center tw-uppercase']"
          :to="button.button_link"
        >
          {{ button.button_label }}
          <span class="btn-arrow">
            <svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                fill="white"
              />
            </svg>
          </span>
        </nuxt-link>
      </div>
    </div>
  </section>

  <div
    v-if="variants.v1 === variant && sidebarComponents.length > 0"
    :style="`background-color: var(--c-${vars.backgroundColor});border-radius: var(--rounded); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
    class="tw-mt-10 tw-px-7 tw-py-10"
  >
    <div class="tw-flex tw-w-full tw-max-w-none tw-flex-col tw-items-center">
      <MotionGroup
        is="div"
        preset="slideVisibleLeft"
        :duration="800"
        class="tw-mb-6 tw-flex tw-w-full tw-flex-col tw-gap-4"
      >
        <h4 :style="`color: var(--c-${vars.titleColor});`">
          {{ vars.titleText }}
        </h4>

        <div class="tw-hidden tw-gap-3 lg:tw-flex">
          <nuxt-link
            v-if="buttons.length > 0"
            v-for="(button, index) in buttons"
            :key="`button-desktop-${index}`"
            :class="['tw-w-full lg:tw-w-auto', `n-${button.button_type}`, 'tw-text-center tw-uppercase']"
            :to="button.button_link"
          >
            {{ button.button_label }}
            <span class="btn-arrow">
              <svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                  fill="white"
                />
              </svg>
            </span>
          </nuxt-link>
        </div>
      </MotionGroup>

      <div class="tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-gap-10">
        <div class="content-container">
          <div class="swiper-wrapper">
            <Swiper
              :key="'property-swiper-' + properties.length"
              :modules="[SwiperPagination]"
              :pagination="false"
              :slides-per-view="1"
              :space-between="20"
              effect="fade"
              :clickable="true"
              :watch-overflow="true"
              @slideChange="onSlideChange"
              @swiper="onSwiper"
              class="tw-w-full"
            >
              <SwiperSlide v-for="(property, index) in properties" :key="`latestproperties-property-${index}`">
                <theme2-properties-property-list-item
                  :property="property"
                  :is-match-information-hidden="true"
                  version="v1"
                  :index="index"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div class="tw-w-full">
          <LibrarySliderPagination
            :swiper="swiper"
            variant="v7"
            :can-paginate-back="canPaginateBack"
            :can-paginate-next="canPaginateNext"
          />
        </div>
      </div>

      <div class="tw-mt-6 tw-block lg:tw-hidden">
        <nuxt-link
          v-for="(button, index) in buttons"
          :key="`button-mobile-${index}`"
          :class="['tw-w-full sm:tw-w-auto', `n-${button.button_type}`, 'tw-text-center tw-uppercase']"
          :to="button.button_link"
        >
          {{ button.button_label }}
          <span class="btn-arrow">
            <svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                fill="white"
              />
            </svg>
          </span>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { PropertiesMixin } from '~/units/properties/mixins/properties.mixin';
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'Theme2LatestProperties',

  mixins: [ComponentMixin, PropertiesMixin],

  data() {
    return {
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  async setup(props) {
    await fetchPropertiesManager(props.component, null);
  },

  mounted() {
    this.onSlideChange();
  },

  methods: {
    onSwiper(swiper: object) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },

  computed: {
    ...mapState(useWebsiteStore, ['sidebarComponents']),
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
