<template>
  <section
    v-if="variants.v1 === variant && sidebarComponents.length === 0"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div
      class="tw-mb-6 tw-flex tw-w-full tw-flex-col tw-items-start tw-justify-between lg:tw-mb-12 lg:tw-flex-row lg:tw-items-center xl:tw-mb-6"
    >
      <h2 :style="`color: var(--c-${vars.titleColor});`" class="tw-mb-4 tw-text-start lg:tw-mb-0">
        {{ vars.titleText }}
      </h2>

      <div class="tw-hidden tw-gap-3 lg:tw-flex">
        <nuxt-link
          v-for="(button, index) in buttons"
          :key="`button-desktop-${index}`"
          :class="['tw-w-full lg:tw-w-auto', `n-${button.button_type}`, 'tw-text-center tw-uppercase']"
          :to="button.button_link"
        >
          {{ button.button_label }}
          <span class="btn-arrow">
            <svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                fill="white"
              />
            </svg>
          </span>
        </nuxt-link>
      </div>
    </div>

    <div class="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-gap-x-10 tw-gap-y-5 xl:tw-flex-col-reverse">
      <MotionGroup
        :preset="vars.animationText ? vars.animationText : 'fadeVisible'"
        class="tw-w-full lg:tw-max-w-full"
        :duration="800"
        is="div"
      >
        <Swiper
          :modules="[SwiperPagination]"
          :pagination="false"
          :slides-per-view="1"
          :space-between="24"
          effect="fade"
          :clickable="true"
          :watch-overflow="true"
          @slideChange="onSlideChange"
          :breakpoints="{
            320: { slidesPerView: 1, spaceBetween: 20 },
            1024: { slidesPerView: 2, spaceBetween: 32 },
            1280: { slidesPerView: 3, spaceBetween: 48 },
          }"
          @swiper="onSwiper"
          class="tw-w-full lg:tw-max-w-full"
        >
          <SwiperSlide v-for="(blog, index) in blogs" :key="`blog-${index}`">
            <lazy-theme2-blogs-blog-card :blog="blog" :index="index" version="v1" />
          </SwiperSlide>
        </Swiper>
      </MotionGroup>

      <!-- Slider Pagination -->
      <div class="tw-mt-4 tw-w-full">
        <LibrarySliderPagination
          :swiper="swiper"
          variant="v7"
          :canPaginateBack="canPaginateBack"
          :canPaginateNext="canPaginateNext"
        />
      </div>
    </div>

    <div class="tw-mt-6 tw-flex tw-flex-col tw-items-center tw-gap-3 lg:tw-hidden">
      <nuxt-link
        v-for="(button, index) in buttons"
        :key="`button-mobile-${index}`"
        :class="['tw-w-full sm:tw-w-auto', `n-${button.button_type}`, 'tw-text-center tw-uppercase']"
        :to="button.button_link"
      >
        {{ button.button_label }}
        <span class="btn-arrow">
          <svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
              fill="white"
            />
          </svg>
        </span>
      </nuxt-link>
    </div>
  </section>

  <div
    v-if="variants.v1 === variant && sidebarComponents.length > 0"
    :style="`background-color: var(--c-${vars.backgroundColor}); border-radius: var(--rounded)`"
    class="tw-mt-10 tw-px-7 tw-py-10"
  >
    <MotionGroup is="div" preset="slideVisibleLeft" :duration="800" class="tw-mb-6 tw-flex tw-flex-col">
      <h4 v-if="vars.titleText" :style="`color: var(--c-${vars.titleColor});`" class="tw-mb-4 tw-justify-self-start">
        {{ vars.titleText }}
      </h4>

      <div class="tw-hidden tw-gap-3 lg:tw-flex" v-if="buttons.length > 0">
        <nuxt-link
          v-for="(button, index) in buttons"
          :key="`button-desktop-${index}`"
          :class="['tw-w-full lg:tw-w-auto', `n-${button.button_type}`, 'tw-text-center tw-uppercase']"
          :to="button.button_link"
        >
          {{ button.button_label }}
          <span class="btn-arrow">
            <svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                fill="white"
              />
            </svg>
          </span>
        </nuxt-link>
      </div>
    </MotionGroup>

    <div class="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-gap-x-10 tw-gap-y-5">
      <MotionGroup
        :preset="vars.animationText ? vars.animationText : 'fadeVisible'"
        class="tw-w-full lg:tw-max-w-full"
        :duration="800"
        is="div"
      >
        <Swiper
          :pagination="false"
          :slides-per-view="1"
          :space-between="10"
          effect="fade"
          :clickable="true"
          :watch-overflow="true"
          @slideChange="onSlideChange"
          @swiper="onSwiper"
          class="tw-w-full lg:tw-max-w-full"
        >
          <SwiperSlide v-for="(blog, index) in blogs" :key="`blog-${index}`">
            <lazy-theme2-blogs-blog-card :blog="blog" :index="index" version="v1" :sidebarComponent="true" />
          </SwiperSlide>
        </Swiper>
      </MotionGroup>

      <!-- Slider Pagination -->
      <div class="tw-mt-4 tw-w-full">
        <LibrarySliderPagination
          :swiper="swiper"
          variant="v7"
          :canPaginateBack="canPaginateBack"
          :canPaginateNext="canPaginateNext"
        />
      </div>
    </div>

    <div class="tw-mt-6 tw-flex tw-flex-col tw-items-center tw-gap-3" v-if="buttons.length > 0">
      <nuxt-link
        v-for="(button, index) in buttons"
        :key="`button-mobile-${index}`"
        :class="['tw-w-full sm:tw-w-auto', `n-${button.button_type}`, 'tw-text-center tw-uppercase']"
        :to="button.button_link"
      >
        {{ button.button_label }}
        <span class="btn-arrow">
          <svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
              fill="white"
            />
          </svg>
        </span>
      </nuxt-link>
    </div>
  </div>
</template>

<script lang="ts">
import type { Component } from '~/types';
import Loader from '~/components/common/Loader.vue';
import NotFound from '~/components/theme2/base/NotFound.vue';
import indexGlobal from '~/mixins/index.global';
import { BlogsMixin } from '~/units/blogs/mixins/blogs.mixin';
import { ComponentMixin } from '~/mixins/component.mixin';
import { useBlogsStore } from '~/units/blogs/store';
import SearchBox from '~/components/common/SearchBox.vue';
import { ROUTES } from '~/constants/ui.constants';
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'Theme2BlogSection',

  mixins: [BlogsMixin, indexGlobal, ComponentMixin],

  async setup(props: { component: Component }) {
    const blogsStore = useBlogsStore();
    await blogsStore.fetchBlogsInPage(props.component);
  },

  data() {
    return {
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    ROUTES() {
      return ROUTES;
    },
    buttons() {
      return this.groupedVariables.buttons;
    },

    ...mapState(useWebsiteStore, ['sidebarComponents']),
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },

  mounted() {
    this.onSlideChange();
  },
});
</script>
