<template>
  <component
    :is="component.component.name"
    v-for="(component, index) in specificComponents"
    :key="`${keyPrefix}-${index}`"
    :component="component"
  ></component>
</template>

<script lang="ts">
// common
import Separator from '~/components/library/Separator.vue';
import FormContainer from '~/components/library/FormContainer.vue';
import SmallBox from '~/components/theme2/components/SmallBox.vue';
import ContactForm from '~/components/theme2/components/ContactForm.vue';
import LatestProperties from '~/components/theme2/properties/LatestProperties.vue';
import BlogSection from '~/components/theme2/blogs/BlogSection.vue';

export default defineNuxtComponent({
  props: {
    specificComponents: {
      required: false,
      default: () => [],
    },

    keyPrefix: {
      required: false,
      type: String,
      default: 'dynamicComponent',
    },
  },

  components: {
    Separator,
    FormContainer,
    ContactForm,
    SmallBox,
    LatestProperties,
    BlogSection,
  },
});
</script>
