<template>
  <div v-if="variants.v1 === variant && sidebarComponents.length > 0">
    <div
      class="tw-grid tw-w-full tw-grid-cols-12 tw-grid-rows-1 tw-items-center tw-bg-[var(--c-section_tertiary)] tw-px-6 tw-py-10"
      :style="`background-color: var(--c-${vars.backgroundColor}); color: var(--c-${vars.textColor})`"
      style="border-radius: var(--rounded)"
    >
      <MotionGroup preset="slideVisibleLeft" is="div" :duration="800" :class="['tw-order-2 tw-col-span-full']">
        <h4 class="tw-mb-4 tw-text-[var(--c-primary)]">{{ vars.titleText }}</h4>
        <div class="tw-flex tw-flex-col tw-gap-3" v-if="descriptions.length > 0">
          <p
            v-for="(description, index) in descriptions"
            :key="`contentbox-description-${index}`"
            class="tw-text-left"
            :style="`color: var(--c-${vars.textColor})`"
          >
            {{ description.description }}
          </p>
        </div>
        <div class="tw-mt-8 tw-flex tw-flex-wrap tw-gap-3">
          <nuxt-link :to="`${button.link}`" v-for="button in buttons" :class="`n-${button.type}`">
            {{ button.label }}
            <span class="btn-arrow"
              ><svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                  fill="white"
                />
              </svg>
            </span>
          </nuxt-link>
        </div>
      </MotionGroup>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'SmallBox',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
      },
      isVisible: false,
      plainText: '',
    };
  },

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
    buttons() {
      return this.groupedVariables.buttons;
    },

    ...mapState(useWebsiteStore, ['sidebarComponents']),
  },
});
</script>
